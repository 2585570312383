import styled from "@emotion/styled"
import mq from "@utils/mq"

const Wrap = styled.div`
  --wrap-padding-y: 1rem;

  padding: var(--wrap-padding-y) 1rem;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 45rem) {
    display: flex;
    justify-content: center;
  }

  ${mq("large")} {
    --wrap-padding-y: 2rem;
  }
`

const Products = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: masonry;
  justify-content: center;
  max-width: calc(80rem + 3px);
  margin-right: 1px;
  margin-bottom: 1px;

  @media (min-width: 43rem) {
    grid-template-columns: repeat(auto-fill, 20rem);
  }
`

const ProductsTop = styled(Products)`
  article:nth-of-type(1),
  article:nth-of-type(2) {
    grid-column: span 2;

    h3 {
      transform: translateY(-3rem);
    }
  }


  @media (min-width: 43rem) {
    grid-template-columns: repeat(auto-fill, 10rem);

    article {
      grid-column: span 2;
      min-height: 21.875rem;
    }

    article:nth-of-type(1) {
      grid-column: span 4;

    }

    article:nth-of-type(2) {
      grid-column: span 3;
      align-self: end;
    }

    article:nth-of-type(3) {
      align-self: end;
    }
  }
`

const Item = styled.article`
  border: 1px solid var(--border-color);
  margin-right: -1px;
  margin-bottom: -1px;
  display: grid;

  a,
  > div {
    color: var(--text-high);
    display: grid;
    grid-template-areas: "image"
                         "name"
                         "data";
    align-items: start;
    justify-items: center;
    text-decoration: none;
    grid-template-rows: auto auto 1fr;
  }

  figure {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
  }

  h3 {
    font-size: 0.875rem;
    line-height: 1.3;
    text-align: center;
    margin-top: -1rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 1rem;
    position: relative;
  }

  span,
  data {
    font-family: var(--monospace);
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.75rem 1rem;
    border-width: 1px;
    border-style: solid;
  }

  span {
    color: var(--text-low);
    border-color: transparent;
    border-bottom: 0;
    border-left: 0;
    justify-self: start;
    align-self: end;
    grid-area: data;
  }

  data {
    color: var(--text-normal);
    border-color: var(--border-color);
    border-bottom: 0;
    border-right: 0;
    justify-self: end;
    align-self: end;
    grid-area: data;
  }

  ${mq("small")} {
    h3,
    data {
      font-size: 1rem;
    }

    span {
      padding-top: 0.8125rem;
      padding-bottom: 0.8125rem;
    }

    h3 {
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }

  ${mq("medium")} {
    figure {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    h3 {
      margin-left: 4rem;
      margin-right: 4rem;
    }
  }

  &.unavailable {
    figure {
      filter: grayscale(100%);
      opacity: 0.3;
    }
  }
`

export {
  Wrap,
  Products,
  ProductsTop,
  Item,
}
